import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'contexts/AuthProvider';
import { SessionProvider } from 'contexts/SessionProvider';
import { LoggingProvider } from 'contexts/logging';
import Toast from './components/Toast';

import Router from './routes';
import ThemeProvider from './themes';

function App() {
    return (
        <LoggingProvider>
            <SessionProvider>
                <AuthProvider>
                    <HelmetProvider>
                        <BrowserRouter>
                            <ThemeProvider>
                                <Toast />
                                <Router />
                            </ThemeProvider>
                        </BrowserRouter>
                    </HelmetProvider>
                </AuthProvider>
            </SessionProvider>
        </LoggingProvider>
    );
}

export default Sentry.withProfiler(App);
