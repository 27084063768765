import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Toast(props) {
    return (
        <ToastContainer
            position="bottom-center"
            style={{ width: '80%', maxWidth: '600px' }}
            autoClose={10000}
            limit={3}
            pauseOnHover
            closeOnClick
            theme="colored"
            {...props}
        />
    );
}
