import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { useLogging } from 'contexts/logging';
import { useSessionId } from 'contexts/SessionProvider';
import { toast } from 'react-toastify';

/**
 * @name FormWizard
 *
 * @desc FormWizard feature.
 * Setups and renders a Formik Form
 *
 * @feature
 * @category Common
 * @param {Object} formModel - JSON object of the form properties
 * @param {React.ComponentType} SubmitComponent - The Submit Component to be used when submitting the form
 * @return {React.ComponentType} FormWizard (Formik) component JSX.
 * @example
 * <FormWizard formModel={formModel} submitComponent={SubmitComponent} />
 */
export default function FormWizard({ formModel, SubmitComponent, initialData }) {
    const logging = useLogging();
    const sessionId = useSessionId();
    const { steps } = formModel;

    /* Initial form values can come from two places
      1. The form model
      2. The initialData prop
      If both are populated for the same field, initialData prop has higher priority
   */
    const formInitialValues = () => {
        let ret = {};
        steps.forEach((step) => {
            Object.keys(step.formField).forEach((fieldName) => {
                ret = { ...ret, [fieldName]: step.formField[fieldName].initial };
            });
        });
        return { ...ret, ...initialData, ...{ sessionId } };
    };

    const [activeStep, setActiveStep] = useState(formModel.steps[0]);
    const currentValidationSchema = activeStep.validationSchema;
    const isSubmissionStep = activeStep.name === formModel.submissionStep;
    const isLastStep = activeStep.order === steps.length - 1;

    function renderStepContent(step) {
        return React.cloneElement(step.render, { ...step });
    }

    const sleep = (delay) => (resolve) => setTimeout(resolve, delay);

    async function submitForm(values, actions) {
        try {
            // Get reCAPTCHA token before submission
            const captchaToken = await new Promise((resolve, reject) => {
                if (!window.grecaptcha) {
                    reject(new Error('reCAPTCHA not loaded'));
                    return;
                }

                window.grecaptcha.enterprise.ready(() => {
                    window.grecaptcha.enterprise
                        .execute(process.env.REACT_APP_SITE_KEY, { action: 'submit' })
                        .then(resolve)
                        .catch(reject);
                });
            });
            await sleep(1000);
            const processedValues = (formModel.submissionPreprocess && formModel.submissionPreprocess(values)) || values;
            const response = await formModel.submissionAPI({
                ...processedValues,
                recaptcha_token: captchaToken,
            });
            logging.log('post submit :: response ::', response);
            actions.setSubmitting(false);
            setActiveStep(steps[activeStep.order + 1]);
        } catch (error) {
            logging.log('post submit :: error ::', error);
            toast.error(`Submission failed`);
            actions.setSubmitting(false);
        }
    }

    function doSubmit(values, actions) {
        logging.log('doSubmit :: ', values, actions);
        if (isSubmissionStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(steps[activeStep.order + 1]);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function handleBack() {
        setActiveStep(steps[activeStep.order - 1]);
    }

    return (
        <Formik
            validationSchema={currentValidationSchema}
            onSubmit={(values, actions) => doSubmit(values, actions)}
            enableReinitialize
            initialValues={formInitialValues()}
            validateOnMount
        >
            {({ isSubmitting, isValid }) => (
                <Form id={formModel.formId}>
                    {renderStepContent(activeStep)}
                    {!isLastStep && (
                        <>
                            {formModel.showBackButton && activeStep !== 0 && (
                                <Button onClick={() => handleBack()}>Back</Button>
                            )}
                            <Box>
                                {SubmitComponent ? (
                                    <SubmitComponent isValid={isValid} isSubmitting={isSubmitting} />
                                ) : (
                                    <>
                                        <Button
                                            disabled={!isValid || isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {isSubmissionStep ? 'Submit' : 'Next'}
                                        </Button>
                                        {isSubmitting && <CircularProgress size={24} />}
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
}

FormWizard.propTypes = {
    formModel: PropTypes.instanceOf(Object).isRequired,
    SubmitComponent: PropTypes.func,
    initialData: PropTypes.instanceOf(Object),
};
