import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthCheck from 'features/AuthCheck';
import EntryForm, { withUserCheck, withSessionData } from 'app/EntryForm/EntryForm';
import Loadable from './components/loadable';
import Status404 from './pages/Status404';
import LoginRedirect from './pages/LoginRedirect';

const SinglePageLayout = Loadable(lazy(() => import('./layouts/singlePage')));
const MinimalLayout = Loadable(lazy(() => import('./layouts/minimal')));

const EntryFormWithUserCheck = withUserCheck(EntryForm);
const EntryFormWithSessionData = withSessionData(EntryForm);

export default function Router() {
    const routes = useRoutes([
        {
            path: '/404',
            element: <MinimalLayout />,
            children: [
                { path: '', element: <Status404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '/investor',
            element: (
                <AuthCheck>
                    <SinglePageLayout />
                </AuthCheck>
            ),
            children: [{ path: '', element: <EntryFormWithUserCheck /> }],
        },
        {
            path: '/',
            element: <SinglePageLayout />,
            children: [{ path: '', element: <EntryFormWithSessionData /> }],
        },
        {
            path: '/login/',
            element: <MinimalLayout />,
            children: [{ path: '', element: <LoginRedirect /> }],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
