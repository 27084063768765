import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLogging } from 'contexts/logging';

// Function to generate a unique session ID
/* eslint-disable no-bitwise */
function generateSessionId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) + 0x8;
        return v.toString(16);
    });
}

const SessionContext = createContext();

export function SessionProvider({ children }) {
    const logging = useLogging();
    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {
        // Check for existing session ID in localStorage
        let sessId = localStorage.getItem('sessionId');
        if (!sessId) {
            // Generate a new session ID
            sessId = generateSessionId();
            localStorage.setItem('sessionId', sessId);
        }
        logging.log('SessionProvider :: sessId :: ', sessId);
        setSessionId(sessId);
    }, [logging]);

    const sessionValue = useMemo(() => sessionId, [sessionId]);

    return <SessionContext.Provider value={sessionValue}>{children}</SessionContext.Provider>;
}

export function useSessionId() {
    return useContext(SessionContext);
}

SessionProvider.propTypes = {
    children: PropTypes.node,
};
